import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import logo from "/src/images/iso_color.png";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Warranty = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Terms Conditions | Custom Surgical" />
      <div
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "1200px",
          paddingTop: "4em",
          marginBottom: "2em",
        }}
      >
        <div className="container3" id="footer_page_flex">
          <div id="footer_page_width1">
            <h1 style={{ marginTop: "0" }}>Terms &amp; Conditions</h1>
            <p style={{ color: "#9c9c9c" }}>Last updated August 8, 2021</p>
          </div>
          <div id="footer_page_width2">
            <h1 style={{ marginTop: "0" }}>1. General provisions</h1>
            <p>
              These Terms of Use constitute a legally binding agreement made
              between you, whether personally or on behalf of an entity ("you",
              “Customer"), and Custom Surgical GmbH ("Company', "we", "us", or
              "our), concerning your access to and use of the{" "}
              <a href="/" className="links_post">
                https://customsurgical.co
              </a>{" "}
              Website as well as any other media form, media channel, mobile
              Website or mobile application related, linked, or otherwise
              connected thereto (collectively, the "Website"). You agree that by
              accessing the Website, you have read, understood, and agreed to be
              bound by all of these Terms of Use.{" "}
              <b>
                IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE
                EXPRESSLY PROHIBITED FROM USING THE Website AND YOU MUST
                DISCONTINUE USE IMMEDIATELY.{" "}
              </b>
            </p>
            <p>
              Supplemental terms and conditions or documents (such as Warranty
              Policy and Shipping policy) that are or may be posted on the
              Website from time to time, are hereby expressly incorporated
              herein by reference and an integral part of these Terms and
              Conditions.{" "}
            </p>
            <p>
              We reserve the right, in our sole discretion, to make changes or
              modifications to these Terms of Use at any time and for any
              reason. We will alert you about any changes by updating the "Last
              updated" date of these Terms of Use, and you waive any right to
              receive specific notice of each such change. It is your
              responsibility to periodically review these Terms of Use to stay
              informed of updates. You will be subject to, and will be deemed to
              have been made aware of and to have accepted, the changes in any
              revised Terms of Use by your continued use of the Website after
              the date such revised Terms of Use are posted.{" "}
            </p>
            <p>
              The information provided on the Website is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Website from other
              locations do so on their own initiative and are solely responsible
              for compliance with local laws, if and to the extent local laws
              are applicable.
            </p>
            <h1 className="container3 reveal fade-right">
              2. Definition of terms
            </h1>
            <p className="container3 reveal fade-left">
              2.1. "Website" means the website on the Internet at{" "}
              <a href="/" className="links_post">
                https://customsurgical.co.
              </a>
            </p>
            <p className="container3 reveal fade-left">
              2.2. "Distributor" means a legal entity, individual entrepreneur
              or person, carrying out independent professional activities, sells
              and promotes our goods and services under the terms of a separate
              agreement, concluded between Company and Distributor.{" "}
            </p>
            <p className="container3 reveal fade-left">
              2.3. "Customer" means a user of the Website, a legal entity or
              individual who has full legal capacity under the applicable
              legislation and interested in purchasing our goods and services,
              who carries out lawful access to the Website and its functionality
              by means of the Internet.
            </p>
            <p className="container3 reveal fade-left">
              2.4. "Service" means online application tool, offered by the
              Website.
            </p>
            <p className="container3 reveal fade-left">
              2.5. "Distributor's Personal Account" means a personal closed
              account of the Distributor on the Website.
            </p>
            <p className="container3 reveal fade-left">
              2.6. "Website Content" means protected results of intellectual
              activity, including abstracts, articles, illustrations, covers,
              musical works with or without text, graphic, text, photographic,
              derivative, Website and other works, user interfaces, visual
              interfaces, trademark names, logos, computer programs, databases,
              as well as the design, structure, selection, coordination,
              appearance, general style and arrangement of such Content included
              in the Website, and other intellectual property of the Website.
            </p>
            <p className="container3 reveal fade-left">
              2.7. "Content" means any information in any form, including text,
              photos, graphic images, placed, reproduced, brought to general or
              individual notice of the Customer or the Distributor on the
              Website.
            </p>
            <h3 className="container3 reveal fade-right">
              3. Registration and account. KYC
            </h3>
            <p className="container3 reveal fade-left">
              You may be required to register with the Website, especially if
              you’re a distributor and want to place your orders directly with
              us. You agree to keep your password confidential and will be
              responsible for all use of your account and password.
            </p>
            <p className="container3 reveal fade-left">
              One unique Personal Account can be created for one Distributor.
              Creation of more than one Personal Account for one Distributor
              shall be a violation of these Terms and may cause the termination
              of the Distributor's account and cancellation of all orders
              without any compensation on our part.
            </p>
            <p className="container3 reveal fade-left">
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Website (or any portion thereof).{" "}
            </p>
            <p className="container3 reveal fade-left">
              The Distributor is obliged to immediately notify us of any changes
              to their data, in particular to their email address. We are not
              liable for any damage caused to the Distributor in connection with
              the lack of data updating.
            </p>
            <p className="container3 reveal fade-left">
              Distributors are solely responsible for any activity on their
              accounts. Logins and passwords are confidential information of
              Distributors. If a third party obtains information about a
              Distributors account, that Distributor shall be solely responsible
              for all possible consequences.
            </p>
            <p className="container3 reveal fade-left">
              The Company reserves the right to suspend the provision of
              services offered via the Website to you and/or block access to
              your Personal Account if the Distributor violates this Terms of
              Use and/or any other rules and policies, published on the Website.
              In such a case, the Company shall not be liable for losses
              incurred by the Distributor.
            </p>
            <div className="container3 reveal fade-left">
              <ul>By using the Website, you represent and warrant that: </ul>
              <li>
                1. all registration information you submit will be true,
                accurate, current, and complete;
              </li>
              <li>
                2. you will maintain the accuracy of such information and
                promptly update such registration information as necessary;
              </li>
              <li>
                3. you have the legal capacity and you agree to comply with
                these Terms of Use;
              </li>
              <li>
                4. you are not a minor in the jurisdiction in which you reside
                and posses full legal capacity;
              </li>
              <li>
                5. you will not access the Website through automated or
                non-human means, whether through a bot, script or otherwise;
              </li>
              <li>
                6. you will not use the Website for any illegal or unauthorized
                purpose;
              </li>
              <li>
                7. your use of the Website will not violate any applicable law
                or regulation.
              </li>
            </div>
            <p className="container3 reveal fade-left">
              To help governmental and international authorities fight the
              funding of terrorism and money laundering activities, the
              applicable legislation requires to obtain, verify, and record
              information that identifies each Person (invividual or a legal
              entity) before establishing the business relationship, including
              applicable “Know Your Customer” (KUC) requirements.
            </p>
            <p className="container3 reveal fade-left">
              Each Customer and/or Distributor shall, promptly upon the request
              of the Company, supply the Company with documentation or other
              evidence as it reasonably requests for itself (or a prospective
              assignee) to be satisfied that it has complied with all necessary
              “know your customer” or other similar checks under all applicable
              laws and regulations in relation to the transactions contemplated.
              If Customer and/or Distributor will not provide mentioned in this
              paragraph requested documents within a reasonable time, the
              Company may, in its own discretion, stop and/or cancel the
              transaction(s) with such Customer and/or Distributor without any
              indemnification.
            </p>
            <h1 className="container3 reveal fade-right">4. Products</h1>
            <p className="container3 reveal fade-left">
              We make every effort to display as accurately as possible the
              colors, features, specifications, and details of the products
              available on the Website. However, we do not guarantee that the
              colors, features, specifications, and details of the products will
              be accurate, complete, reliable, current, or free of other errors,
              and your electronic display may not accurately reflect the actual
              colors and details of the products. All products are subject to
              availability, and we cannot guarantee that items will be in stock.
              We reserve the right to discontinue any products at any time for
              any reason.{" "}
            </p>
            <p className="container3 reveal fade-left">
              We reserve the right to change our fees from time to time by
              posting the changes on the Website with no advance notice to
              Customers. By making an order(s), Customers confirm that they
              completely agree with the charges published on the Website.
            </p>
            <h1 className="container3 reveal fade-right">
              5. Purchases and payment
            </h1>
            <p className="container3 reveal fade-left">
              We accept payments through Stripe payment service. You agree to
              provide current, complete, and accurate purchase and account
              information for all purchases made via the Website. You may need
              to provide your payment information, including email address,
              payment method, and payment card expiration date, so that we can
              complete your transactions and contact you as needed. We do not
              store or collect your payment information. Sales tax will be added
              to the price of purchases as deemed required by us. We may change
              prices at any time.{" "}
            </p>
            <p className="container3 reveal fade-left">
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order. We reserve the right to correct any errors or
              mistakes in pricing, even if we have already requested or received
              payment.
            </p>
            <p className="container3 reveal fade-left">
              The Customer is liable for all transaction fees arising out of all
              fees made using some or all of the Service, even if sales terms
              are finalized or payment is made outside of the Website. The
              Customer is also solely liable for all possible transfer risks,
              including, but not limited to, technical, tax, fines, losses
              incurred by third parties, etc.
            </p>
            <p className="container3 reveal fade-left">
              We reserve the right to refuse any order placed through the
              Website. We may, in our sole discretion, limit or cancel
              quantities purchased per person, per household, or per order.
              These restrictions may include orders placed by or under the same
              Customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole judgment, appear to be
              placed by dealers, resellers, or distributors.
            </p>
            <h1 className="container3 reveal fade-right">
              6. Warranty and returns
            </h1>
            <p className="container3 reveal fade-left">
              Physical unused, unopened, and resalable products may be returned
              for refund within the ﬁrst 14 (fourteen) days from the date of
              delivery (after placing a relevant order). We also offer a 2-year
              warranty from manufacturing issues.
            </p>
            <p className="container3 reveal fade-left">
              No Products, goods and parts, previously purchased on the Website,
              shall be returned to us, whether for inspection, repair,
              replacement, or any other reason, without prior written approval
              from the Company. Products and parts must be returned in new
              condition with complete identification in accordance with our
              instruction, in another case the shipment may not be accepted. All
              returns must be sent DDP to Seller’s designated location (per
              Incoterms ICC 2020) unless otherwise instructed in writing by the
              Company.{" "}
            </p>
            <p className="container3 reveal fade-left">
              Where authorization has been obtained to return Products and parts
              for reasons beyond warranty, a restocking charge and any
              additional transportation charges are applicable.
            </p>
            <p className="container3 reveal fade-left">
              Please review our{" "}
              <a className="links_post" href="/warranty">
                Warranty Policy
              </a>{" "}
              posted on the Website prior to making any purchases.
            </p>
            <h1 className="container3 reveal fade-right">
              7. Intellectual property rights
            </h1>
            <p className="container3 reveal fade-left">
              Unless otherwise indicated, the Website is our proprietary
              property and all source code, databases, functionality, software,
              Website designs, audio, video, text, photographs, and graphics on
              the Website (collectively, the "Content") and the trademarks,
              service marks, and logos contained therein (the "Marks") are owned
              or controlled by us or licensed to us, and are protected by
              copyright and trademark laws and various other intellectual
              property rights and unfair competition laws of the United States,
              international copyright laws, and international conventions. The
              Content and the Marks are provided on the Website "AS IS" for your
              information and personal use only. Except as expressly provided in
              these Terms of Use, no part of the Website and no Content or Marks
              may be copied, reproduced, aggregated, republished, uploaded,
              posted, publicly displayed, encoded, translated, transmitted,
              distributed, sold, licensed, or otherwise exploited for any
              commercial purpose whatsoever, without our express prior written
              permission.{" "}
            </p>
            <p className="container3 reveal fade-left">
              Provided that you are eligible to use the Website, you are granted
              a limited license to access and use the Website and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Website, the Content and the Marks.
            </p>
            <h1 className="container3 reveal fade-right">
              8. Prohibited activities
            </h1>
            <p className="container3 reveal fade-left">
              You may not access or use the Website for any purpose other than
              that for which we make the Website available. The Website may not
              be used in connection with any commercial endeavors except those
              that are specifically endorsed or approved by us.{" "}
            </p>
            <div className="container3 reveal fade-left">
              <ul>As a Customer of the Website, you agree not to: </ul>
              <li>
                1. Systematically retrieve data or other content from the
                Website to create or compile, directly or indirectly, a
                collection, compilation, database, or directory without written
                permission from us.{" "}
              </li>
              <li>
                2. Make any unauthorized use of the Website, including
                collecting Customer names and/or email addresses of Customers by
                electronic or other means for the purpose of sending unsolicited
                email, or creating Customer accounts by automated means or under
                false pretenses.{" "}
              </li>
              <li>
                3. Use the Website to advertise or offer to sell goods and
                services.{" "}
              </li>
              <li>
                4. Circumvent, disable, or otherwise interfere with
                security-related features of the Website, including features
                that prevent or restrict the use or copying of any Content or
                enforce limitations on the use of the Website and/or the Content
                contained therein.{" "}
              </li>
              <li>
                5. Engage in unauthorized framing of or linking to the Website.
              </li>
              <li>
                6. Trick, defraud, or mislead us and other Customers, especially
                in any attempt to learn sensitive account information such as
                Customer passwords.{" "}
              </li>
              <li>
                7. Make improper use of our support services or submit false
                reports of abuse or misconduct.{" "}
              </li>
              <li>
                8. Engage in any automated use of the system, such as using
                scripts to send comments or messages, or using any data mining,
                robots, or similar data gathering and extraction tools.{" "}
              </li>
              <li>
                9. Interfere with, disrupt, or create an undue burden on the
                Website or the networks or services connected to the Website.{" "}
              </li>
              <li>
                10. Attempt to impersonate another Customer or person or use the
                Customername of another Customer.
              </li>
              <li>11. Sell or otherwise transfer your profile. </li>
              <li>
                12. Use any information obtained from the Website in order to
                harass, abuse, or harm another person.{" "}
              </li>
              <li>
                13. Use the Website as part of any effort to compete with us or
                otherwise use the Website and/or the Content for any
                revenue-generating endeavor or commercial enterprise.{" "}
              </li>
              <li>
                14. Decipher, decompile, disassemble, or reverse engineer any of
                the software comprising or in any way making up a part of the
                Website.{" "}
              </li>
              <li>
                15. Attempt to bypass any measures of the Website designed to
                prevent or restrict access to the Website, or any portion of the
                Website.{" "}
              </li>
              <li>
                16. Harass, annoy, intimidate, or threaten any of our employees
                or agents engaged in providing any portion of the Website to
                you.{" "}
              </li>
              <li>
                17. Delete the copyright or other proprietary rights notice from
                any Content.{" "}
              </li>
              <li>
                18. Copy or adapt the Website's software, including but not
                limited to Flash, PHP, HTML, JavaScript, or other code.{" "}
              </li>
              <li>
                19. Upload or transmit (or attempt to upload or to transmit)
                viruses, Trojan horses, or other material, including excessive
                use of capital letters and spamming (continuous posting of
                repetitive text), that interferes with any party's uninterrupted
                use and enjoyment of the Website or modifies, impairs, disrupts,
                alters, or interferes with the use, features, functions,
                operation, or maintenance of the Website.
              </li>
              <li>
                20. Upload or transmit (or attempt to upload or to transmit) any
                material that acts as a passive or active information collection
                or transmission mechanism, including without limitation, clear
                graphics interchange formats ("gifs"), 1x1 pixels, web bugs,
                cookies, or other similar devices (sometimes referred to as
                "spyware" or "passive collection mechanisms" or "pcms").{" "}
              </li>
              <li>
                21. Except as may be the result of standard search engine or
                Internet browser usage, use, launch, develop, or distribute any
                automated system, including without limitation, any spider,
                robot, cheat utility, scraper, or offline reader that accesses
                the Website, or using or launching any unauthorized script or
                other software.{" "}
              </li>
              <li>
                22. Disparage, tarnish, or otherwise harm, in our opinion, us
                and/or the Website.{" "}
              </li>
              <li>
                23. Use the Website in a manner inconsistent with any applicable
                laws or regulations.
              </li>
            </div>
            <h1 className="container3 reveal fade-right">
              9. Customer generated content
            </h1>
            <p className="container3 reveal fade-left">
              The Website may invite you to chat, contribute to, or participate
              in blogs, message boards, online forums, and other functionality,
              and may provide you with the opportunity to create, submit, post,
              display, transmit, perform, publish, distribute, or broadcast
              content and materials to us or on the Website, including but not
              limited to text, writings, video, audio, photographs, graphics,
              comments, suggestions, or personal information or other material
              (collectively, "Contributions").{" "}
            </p>
            <div className="container3 reveal fade-left">
              <ul>
                Contributions may be viewable by other Customers of the Website
                and the Marketplace Offerings and through third-party Websites.
                As such, any Contributions you transmit may be treated as
                non-confidential and non-proprietary. When you create or make
                available any Contributions, you thereby represent and warrant
                that:{" "}
              </ul>
              <li>
                1. The creation, distribution, transmission, public display, or
                performance, and the accessing, downloading, or copying of your
                Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party.{" "}
              </li>
              <li>
                2. You are the creator and owner of or have the necessary
                licenses, rights, consents, releases, and permissions to use and
                to authorize us, the Website, and other Customers of the Website
                to use your Contributions in any manner contemplated by the
                Website and these Terms of Use.{" "}
              </li>
              <li>
                3. You have the written consent, release, and/or permission of
                each and every identifiable individual person in your
                Contributions to use the name or likeness of each and every such
                identifiable individual person to enable inclusion and use of
                your Contributions in any manner contemplated by the Website and
                these Terms of Use.{" "}
              </li>
              <li>
                4. Your Contributions are not unsolicited or unauthorized
                advertising, promotional materials, pyramid schemes, chain
                letters, spam, mass mailings, or other forms of solicitation.{" "}
              </li>
              <li>
                5. Your Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us).{" "}
              </li>
              <li>
                6. Your Contributions do not ridicule, mock, disparage,
                intimidate, or abuse anyone.{" "}
              </li>
              <li>
                7. Your Contributions are not used to harass or threaten (in the
                legal sense of those terms) any other person and to promote
                violence against a specific person or class of people.{" "}
              </li>
              <li>
                8. Your Contributions do not violate any applicable law,
                regulation, or rule.{" "}
              </li>
              <li>
                9. Your Contributions do not violate the privacy or publicity
                rights of any third party.{" "}
              </li>
              <li>
                10. Your Contributions do not contain any material that solicits
                personal information from anyone under the age of 18 or exploits
                people under the age of 18 in a sexual or violent manner.{" "}
              </li>
              <li>
                11. Your Contributions do not violate any applicable law
                concerning child pornography, or otherwise intended to protect
                the health or well-being of minors;{" "}
              </li>
              <li>
                12. Your Contributions do not include any offensive comments
                that are connected to race, national origin, gender, sexual
                preference, or physical handicap.{" "}
              </li>
              <li>
                13. Your Contributions do not otherwise violate, or link to
                material that violates, any provision of these Terms of Use, or
                any applicable law or regulation.{" "}
              </li>
              <li>
                14. Any use of the Website or the Marketplace Offerings in
                violation of the foregoing violates these Terms of Use and may
                result in, among other things, termination or suspension of your
                rights to use the Website and the Marketplace Offerings.
              </li>
            </div>
            <h1 className="container3 reveal fade-right">
              10. Contribution license
            </h1>
            <p className="container3 reveal fade-left">
              By posting your Contributions to any part of the Website, you
              automatically grant, and you represent and warrant that you have
              the right to grant, to us an unrestricted, unlimited, irrevocable,
              perpetual, non-exclusive, transferable, royalty-free, fully-paid,
              worldwide right, and license to host, use, copy, reproduce,
              disclose, sell, resell, publish, broadcast, retitle, archive,
              store, cache, publicly perform, publicly display, reformat,
              translate, transmit, excerpt (in whole or in part), and distribute
              such Contributions (including, without limitation, your image and
              voice) for any purpose, commercial, advertising, or otherwise, and
              to prepare derivative works of, or incorporate into other works,
              such Contributions, and grant and authorize sublicenses of the
              foregoing. The use and distribution may occur in any media formats
              and through any media channels.{" "}
            </p>
            <p className="container3 reveal fade-left">
              This license will apply to any form, media, or technology now
              known or hereafter developed, and includes our use of your name,
              company name, and franchise name, as applicable, and any of the
              trademarks, service marks, trade names, logos, and personal and
              commercial images you provide. You waive all moral rights in your
              Contributions, and you warrant that moral rights have not
              otherwise been asserted in your Contributions.{" "}
            </p>
            <p className="container3 reveal fade-left">
              We do not assert any ownership over your Contributions. You retain
              full ownership of all of your Contributions and any intellectual
              property rights or other proprietary rights associated with your
              Contributions. We are not liable for any statements or
              representations in your Contributions provided by you in any area
              on the Website. You are solely responsible for your Contributions
              to the Website and you expressly agree to exonerate us from any
              and all responsibility and to refrain from any legal action
              against us regarding your Contributions.{" "}
            </p>
            <p className="container3 reveal fade-left">
              We have the right, in our sole and absolute discretion, (1) to
              edit, redact, or otherwise change any Contributions; (2) to
              re-categorize any Contributions to place them in more appropriate
              locations on the Website; and (3) to pre-screen or delete any
              Contributions at any time and for any reason, without notice. We
              have no obligation to monitor your Contributions.
            </p>
            <h1 className="container3 reveal fade-right">
              11. Guidelines for reviews
            </h1>
            <div className="container3 reveal fade-left">
              <ul>
                We may provide you areas on the Website to leave reviews or
                ratings. When posting a review, you must comply with the
                following criteria:{" "}
              </ul>
              <li>
                1. you should have firsthand experience with the person/entity
                being reviewed
              </li>
              <li>
                2. your reviews should not contain offensive profanity, or
                abusive, racist, offensive, or hate language
              </li>
              <li>
                3. your reviews should not contain discriminatory references
                based on religion, race, gender, national origin, age, marital
                status, sexual orientation, or disability
              </li>
              <li>
                4. your reviews should not contain references to illegal
                activity
              </li>
              <li>
                5. you should not be affiliated with competitors if posting
                negative reviews
              </li>
              <li>
                6. you should not make any conclusions as to the legality of
                conduct
              </li>
              <li>7. you may not post any false or misleading statements</li>
              <li>
                8. you may not organize a campaign encouraging others to post
                reviews, whether positive or negative.{" "}
              </li>
            </div>
            <p className="container3 reveal fade-left">
              We may accept, reject, or remove reviews at our sole discretion.
              We have absolutely no obligation to screen reviews or to delete
              reviews, even if anyone considers reviews objectionable or
              inaccurate. Reviews are not endorsed by us, and do not necessarily
              represent our opinions or the views of any of our affiliates or
              partners. We do not assume liability for any review or for any
              claims, liabilities, or losses resulting from any review. By
              posting a review, you hereby grant to us a perpetual,
              non-exclusive, worldwide, royalty-free, fully-paid, assignable,
              and sublicensable right and license to reproduce, modify,
              translate, transmit by any means, display, perform, and/or
              distribute all content relating to reviews.
            </p>
            <h1 className="container3 reveal fade-right">12. Submission</h1>
            <p className="container3 reveal fade-left">
              You acknowledge and agree that any questions, comments,
              suggestions, ideas, feedback, or other information regarding the
              Website or the Marketplace Offerings ("Submissions") provided by
              you to us are non-confidential and shall become our sole property.
              We shall own exclusive rights, including all intellectual property
              rights, and shall be entitled to the unrestricted use and
              dissemination of these Submissions for any lawful purpose,
              commercial or otherwise, without acknowledgment or compensation to
              you. You hereby waive all moral rights to any such Submissions,
              and you hereby warrant that any such Submissions are original with
              you or that you have the right to submit such Submissions. You
              agree there shall be no recourse against us for any alleged or
              actual infringement or misappropriation of any proprietary right
              in your Submissions.
            </p>
            <h1 className="container3 reveal fade-right">
              13. Third party Websites and content
            </h1>
            <p className="container3 reveal fade-left">
              The Website may contain (or you may be sent via the Website or the
              Marketplace Offerings) links to other Websites ("Third-Party
              Websites") as well as articles, photographs, text, graphics,
              pictures, designs, music, sound, video, information, applications,
              software, and other content or items belonging to or originating
              from third parties ("Third-Party Content"). Such Third-Party
              Websites and Third-Party Content are not investigated, monitored,
              or checked for accuracy, appropriateness, or completeness by us,
              and we are not responsible for any Third Party Websites accessed
              through the Website or any Third-Party Content posted on,
              available through, or installed from the Website, including the
              content, accuracy, offensiveness, opinions, reliability, privacy
              practices, or other policies of or contained in the Third-Party
              Websites or the Third-Party Content. Inclusion of, linking to, or
              permitting the use or installation of any Third-Party Websites or
              any Third-PartyContent does not imply approval or endorsement
              thereof by us. If you decide to leave the Website and access the
              Third-Party Websites or to use or install any Third-Party Content,
              you do so at your own risk, and you should be aware these Terms of
              Use no longer govern. You should review the applicable terms and
              policies, including privacy and data gathering practices, of any
              Website to which you navigate from the Website or relating to any
              applications you use or install from the Website. Any purchases
              you make through Third-Party Websites will be through other
              Websites and from other companies, and we take no responsibility
              whatsoever in relation to such purchases which are exclusively
              between you and the applicable third party. You agree and
              acknowledge that we do not endorse the products or services
              offered on Third-Party Websites and you shall hold us harmless
              from any harm caused by your purchase of such products or
              services. Additionally, you shall hold us harmless from any losses
              sustained by you or harm caused to you relating to or resulting in
              any way from any Third-Party Content or any contact with
              Third-Party Websites.
            </p>
            <h1 className="container3 reveal fade-right">
              14. Website management
            </h1>
            <div className="container3 reveal fade-left">
              <ul>We reserve the right, but not the obligation, to: </ul>
              <li>
                1. monitor the Website for violations of these Terms of Use
              </li>
              <li>
                2. take appropriate legal action against anyone who, in our sole
                discretion, violates the law or these Terms of Use, including
                without limitation, reporting such Customer to law enforcement
                authorities
              </li>
              <li>
                3. in our sole discretion and without limitation, refuse,
                restrict access to, limit the availability of, or disable (to
                the extent technologically feasible) any of your Contributions
                or any portion thereof
              </li>
              <li>
                4. in our sole discretion and without limitation, notice, or
                liability, to remove from the Website or otherwise disable all
                files and content that are excessive in size or are in any way
                burdensome to our systemsotherwise manage the Website in a
                manner designed to protect our rights and property and to
                facilitate the proper functioning of the Website and the
                Marketplace Offerings.
              </li>
            </div>
            <h1 className="container3 reveal fade-right">
              15. Personal Data Privacy
            </h1>
            <p className="container3 reveal fade-left">
              We care about data privacy and security. Please review our Privacy
              Policy. By using the Website or the Marketplace Offerings, you
              agree to be bound by our{" "}
              <a className="links_post" href="/privacy-policy">
                Privacy Policy
              </a>
              , which is an integral part of legal agreements between Customers
              and us.
            </p>
            <h1 className="container3 reveal fade-right">
              16. Copywriting infringements
            </h1>
            <p className="container3 reveal fade-left">
              We respect the intellectual property rights of others. If you
              believe that any material available on or through the Website
              infringes upon any copyright you own or control, please
              immediately notify us using the contact information provided below
              (a "Notification"). A copy of your Notification will be sent to
              the person who posted or stored the material addressed in the
              Notification. Please be advised that pursuant to applicable law
              you may be held liable for damages if you make material
              misrepresentations in a Notification. Thus, if you are not sure
              that material located on or linked to by the Website infringes
              your copyright, you should consider first contacting an attorney.
            </p>
            <h1 className="container3 reveal fade-right">
              17. Terms and termination
            </h1>
            <p className="container3 reveal fade-left">
              These Terms of Use shall remain in full force and effect while you
              use the Website. WITHOUT LIMITING ANY OTHER PROVISION OF THESE
              TERMS OF USE, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
              WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE Website
              AND THE MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP
              ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
              INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
              WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY
              APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
              PARTICIPATION IN THE Website AND THE MARKETPLACE OFFERINGS OR
              DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED
              AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.{" "}
            </p>
            <p className="container3 reveal fade-left">
              If we terminate or suspend your account for any reason, you are
              prohibited from registering and creating a new account under your
              name, a fake or borrowed name, or the name of any third party,
              even if you may be acting on behalf of the third party. In
              addition to terminating or suspending your account, we reserve the
              right to take appropriate legal action, including without
              limitation pursuing civil, criminal, and injunctive redress.
            </p>
            <h1 className="container3 reveal fade-right">18. Governing law</h1>
            <p className="container3 reveal fade-left">
              These conditions are governed by and interpreted following the
              laws of Germany, and the use of the United Nations Convention of
              Contracts for the International Sales of Goods is expressly
              excluded. Custom Surgical GmbH and yourself both agree to submit
              to the non-exclusive jurisdiction of the courts of Bavaria, which
              means that you may make a claim to defend your consumer protection
              rights in regards to these Conditions of Use in Germany.
            </p>
            <h1 className="container3 reveal fade-right">19. Disclaimer</h1>
            <p className="container3 reveal fade-left">
              THE Website IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
              AGREE THAT YOUR USE OF THE Website SERVICES WILL BE AT YOUR SOLE
              RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
              WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE Website AND
              YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
              ABOUT THE ACCURACY OR COMPLETENESS OF THE Website'S CONTENT OR THE
              CONTENT OF ANY WebsiteS LINKED TO THIS Website AND WE WILL ASSUME
              NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
              PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE Website, (3) ANY UNAUTHORIZED ACCESS TO
              OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
              INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
              INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE Website,
              (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
              TRANSMITTED TO OR THROUGH THE Website BY ANY THIRD PARTY, AND/OR
              (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
              ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
              ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
              THE Website. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
              RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY
              A THIRD PARTY THROUGH THE Website, ANY HYPERLINKED Website, OR ANY
              Website OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER
              ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY BE
              RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
              THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
              PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
              ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
              CAUTION WHERE APPROPRIATE.{" "}
            </p>
            <p className="container3 reveal fade-left">
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
              LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
              CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE
              DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
              OTHER DAMAGES ARISING FROM YOUR USE OF THE Website, EVEN IF WE
              HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
              NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
              LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE
              FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF
              THE AMOUNT PAID, IF ANY, BY YOU TO US. CERTAIN US STATE LAWS AND
              INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES
              OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS
              APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
              MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.{" "}
            </p>
            <p className="container3 reveal fade-left">
              We cannot guarantee the Website and the Marketplace Offerings will
              be available at all times. We may experience hardware, software,
              or other problems or need to perform maintenance related to the
              Website, resulting in interruptions, delays, or errors. We reserve
              the right to change, revise, update, suspend, discontinue, or
              otherwise modify the Website or the Marketplace Offerings at any
              time or for any reason without notice to you. You agree that we
              have no liability whatsoever for any loss, damage, or
              inconvenience caused by your inability to access or use the
              Website or the Marketplace Offerings during any downtime or
              discontinuance of the Website or the Marketplace Offerings.
              Nothing in these Terms of Use will be construed to obligate us to
              maintain and support the Website or the Marketplace Offerings or
              to supply any corrections, updates, or releases in connection
              therewith.
            </p>
            <h1 className="container3 reveal fade-right">
              20. Indemnification
            </h1>
            <p className="container3 reveal fade-left">
              You agree to defend, indemnify, and hold us harmless, including
              our subsidiaries, affiliates, and all of our respective officers,
              agents, partners, and employees, from and against any loss,
              damage, liability, claim, or demand, including reasonable
              attorneys' fees and expenses, made by any third party due to or
              arising out of:{" "}
            </p>
            <p className="container3 reveal fade-left">
              (1) your Contributions; (2) use of the Website; (3) breach of
              these Terms of Use; (4) any breach of your representations and
              warranties set forth in these Terms of Use; (5) your violation of
              the rights of a third party, including but not limited to
              intellectual property rights; or (6) any overt harmful act toward
              any other Customer of the Website with whom you connected via the
              Website. Notwithstanding the foregoing, we reserve the right, at
              your expense, to assume the exclusive defense and control of any
              matter for which you are required to indemnify us, and you agree
              to cooperate, at your expense, with our defense of such claims. We
              will use reasonable efforts to notify you of any such claim,
              action, or proceeding which is subject to this indemnification
              upon becoming aware of it.
            </p>
            <h1 className="container3 reveal fade-right">
              21. Electronic communications, signatures, and data
            </h1>
            <p className="container3 reveal fade-left">
              Visiting the Website, sending us emails, and completing online
              forms constitute electronic communications. You consent to receive
              electronic communications, and you agree that all agreements,
              notices, disclosures, and other communications we provide to you
              electronically, via email and on the Website, satisfy any legal
              requirement that such communication be in writing. YOU HEREBY
              AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
              OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES,
              AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA
              THE Website. You hereby waive any rights or requirements under any
              statutes, regulations, rules, ordinances, or other laws in any
              jurisdiction which require an original signature or delivery or
              retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </p>
            <h1 className="container3 reveal fade-right">22. Miscellaneous</h1>
            <p className="container3 reveal fade-left">
              These Terms of Use and any policies or operating rules posted by
              us on the Website or in respect to the Website constitute the
              entire agreement and understanding between you and us. Our failure
              to exercise or enforce any right or provision of these Terms of
              Use shall not operate as a waiver of such right or provision.
              These Terms of Use operate to the fullest extent permissible by
              law. We may assign any or all of our rights and obligations to
              others at any time. We shall not be responsible or liable for any
              loss, damage, delay, or failure to act caused by any cause beyond
              our reasonable control. If any provision or part of a provision of
              these Terms of Use is determined to be unlawful, void, or
              unenforceable, that provision or part of the provision is deemed
              severable from these Terms of Use and does not affect the validity
              and enforceability of any remaining provisions. There is no joint
              venture, partnership, employment or agency relationship created
              between you and us as a result of these Terms of Use or use of the
              Website. You agree that these Terms of Use will not be construed
              against us by virtue of having drafted them. You hereby waive any
              and all defenses you may have based on the electronic form of
              these Terms of Use and the lack of signing by the parties hereto
              to execute these Terms of Use.{" "}
            </p>
            <p className="container3 reveal fade-left">
              There may be information on the Website that contains
              typographical errors, inaccuracies, or omissions that may relate
              to the Marketplace Offerings, including descriptions, pricing,
              availability, and various other information. We reserve the right
              to correct any errors, inaccuracies, or omissions and to change or
              update the information on the Website at any time, without prior
              notice.
            </p>
            <h1 className="container3 reveal fade-right">23. Contact us</h1>
            <p className="container3 reveal fade-left">
              In order to resolve a complaint regarding the Website or to
              receive further information regarding the use of the Website,
              please contact us at: Custom Surgical GmbH Atelierstralle, 29,
              Munchen 81671 Germany Phone: +4915234689005{" "}
              <a className="links_post" href="mailto:support@customsurgical.co">
                support@customsurgical.co
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Warranty;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
